
import { defineComponent, ref } from 'vue';
import { useStore } from 'vuex';
import router from '@/router';

export default defineComponent({
  name: 'Login',
  setup() {
    const store = useStore();
    const credentials = ref({
      username: undefined,
      password: undefined,
    });
    const errorMessage = ref();

    store.dispatch('auth/LOGOUT');

    const loginAction = async () => {
      try {
        await store.dispatch('auth/LOGIN', { ...credentials.value });
        await store.dispatch('auth/GET_USER_INFO');
        if (store.state.auth.isDisabled) {
          await store.dispatch('auth/LOGOUT');
          alert('У вас недостаточно прав для использования сервиса');
        } else {
          await router.replace('/');
        }
      } catch (e) {
        // eslint-disable-next-line no-alert
        alert('Ошибка авторизации');
      }
    };
    return {
      loginAction, credentials, errorMessage, store,
    };
  },
});
